@import '../../styles/index.scss';

.layoutContainer {
  margin: $margin-0 $spacing_large;
  min-width: calc(1280px - $spacing_xlarge);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.layoutWrapper {
  margin: $page-margin_top $spacing_large;
  min-width: calc(1280px - $spacing_xlarge);
  min-height: 100%;

  @include breakpoint(desktop) {
    margin: $page-margin_top auto $spacing_xlarge;
    max-width: calc(1280px - $spacing_xlarge);
  }

  .vendorListItem,
  .vendorListItemID {
    list-style-type: circle;
  }

  .vendorListItemID {
    margin-left: 30px;
  }

  .vendorListItem {
    margin-left: 20px;
  }
}
