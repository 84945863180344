@import '../../styles/index.scss';

.homeContentContainer {
  background: $color-white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: $spacing_xlarge $spacing_large;
  border-radius: 4px;
  margin-top: -38px;
  position: relative;
  margin-bottom: $spacing_large;
}
