@import '../../styles/index.scss';
@import '../../styles/components/table';

.csvErrorTable {
  .csvErrorHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing_medium;
    margin-bottom: $spacing_large;
    button {
      margin-left: calc($spacing_large * -1);
    }
  }
  .tableContainer {
    .tableHeader {
      padding: $spacing_large;
    }
    .table {
      thead th {
        height: 54px;
        cursor: initial !important;
      }
      .th,
      td {
        max-width: 100px;

        &:nth-of-type(1) {
          max-width: 35px;
        }

        &:nth-of-type(2) {
          max-width: 40px;
        }

        &:nth-of-type(3) {
          max-width: 120px;
        }

        &:nth-of-type(4) {
          max-width: 40px;
        }

        &:nth-of-type(5) {
          max-width: 68px;
        }

        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8),
        &:nth-of-type(9),
        &:nth-of-type(11),
        &:nth-of-type(12),
        &:nth-child(13) {
          max-width: 50px;
        }

        &:nth-of-type(10) {
          max-width: 60px;
        }

        &:nth-child(14) {
          width: 10%;
        }
      }
    }
  }
}
