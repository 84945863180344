@import '../../styles/index.scss';

.productDetails {
  display: flex;
  padding: $spacing_large 0 0;

  &__heading {
    width: $width-modal-sidebar;
    min-width: $width-modal-sidebar;
    padding: $spacing_xsmall $spacing_large $spacing_xsmall 0;

    p {
      line-height: 1;
      margin-bottom: $gap_large;
      overflow-wrap: anywhere;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(min-content, 1fr));
    grid-gap: 0 $gap_large;
    flex: 1;
    max-width: $width-modal-content;
  }

  &__gridItem {
    border-left: 1px solid $color-border_lightGrey;
    white-space: nowrap;
    padding: $spacing_xsmall $spacing_medium;
    line-height: 1;
    max-width: 275px;

    p {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
