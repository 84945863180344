@import '../../styles/index.scss';

.text {
  font-weight: $font-weight_normal;
  font-size: 16px;
  line-height: $spacing_large;

  a,
  a:active {
    color: $color-primary_base;
  }

  a:hover {
    text-decoration: underline;
  }

  &Bold {
    font-weight: $font-weight_bold;
  }

  &Intro {
    font-size: 18px;
    line-height: 26px;
  }

  &Medium {
    font-size: 12px;
  }

  &Small {
    font-size: 12px;
    line-height: 14px;
  }

  &Tiny {
    font-size: 10px;
    line-height: $spacing_small;
  }

  &TableCell {
    font-size: 13px;
    line-height: 17px;
    color: $color-text_paragraph;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &Disabled {
      color: $color-text_placeholder;
    }
  }

  &Action {
    color: $color-red_action;
    &Disabled {
      color: $color-text_placeholder;
    }
  }

  &Table {
    font-weight: $font-weight_normal;
    font-size: 13px;
    line-height: 17px;
    color: $color-text_paragraph;
  }

  &AlignRight {
    text-align: right;
  }

  &Dark {
    color: $color-headings_black;
  }

  &Light {
    color: $color-table_label;
  }

  &UpperCase {
    text-transform: uppercase;
  }

  &ScreenReaderOnly {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
}

.textContainer {
  position: relative;
  .tooltipContainer {
    visibility: hidden;
    position: absolute;
    z-index: 100;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);

    &Top {
      bottom: 100%;
      transform: translate(-50%, -50%);
    }
    &Bottom {
      top: 100%;
      transform: translate(-50%, 25%);
    }
  }
}

.textContainer:hover .tooltipContainer {
  visibility: visible;
}
