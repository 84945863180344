@import '../../../styles/index.scss';

.deleteModalContainer {
  padding: 48px;
  min-width: $width-min-delete-modal;
  min-height: $height-min-delete-modal;

  .contentContainer {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .iconContainer {
    max-height: fit-content;
    max-width: fit-content;
    padding: 16px;
    background-color: $color-background_red;
    border-radius: 50%;

    svg {
      margin: 0 auto;
      color: $color-text_red;
    }
  }
  .buttonContainer {
    border-top: 1px solid $color-background_highlight;
    padding-top: 24px;
    display: flex;

    button:first-of-type {
      padding-left: 0;
      padding-right: 48px;
    }
  }
}
