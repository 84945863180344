@import '../../styles/index.scss';

.react-datepicker {
  * {
    font-family: $text-primary-font-family;
  }

  .calendarContainer {
    button.react-datepicker__navigation.react-datepicker__navigation--next,
    button.react-datepicker__navigation.react-datepicker__navigation--previous {
      color: $color-red_action;
    }
  }
}

.calendarInputContainer {
  display: flex;
  flex-direction: column;
  gap: $gap_small;
  width: 100%;
  position: relative;

  &Small {
    width: 139px;
    max-width: 139px;
  }

  &Large {
    width: 183px;
    max-width: 183px;
  }

  .textContainer {
    height: 14px;
  }

  .iconContainer {
    position: absolute;
    color: $color-text_paragraph;
    right: $spacing_small;
    top: 40px;
  }

  input {
    border: 1px solid $color-background_mediumGrey;
    border-radius: $border-radius_xsmall;
    padding: 9px $padding_medium;
    font-style: normal;
    font-weight: $font-weight_normal;
    font-size: 14px;
    line-height: 22px;
    color: $color-text_black;
    width: inherit;

    &::placeholder {
      color: $color-text_black;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $color-text_black;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $color-text_black;
    }

    &Error {
      border: 2px solid $color-red_danger !important;
    }

    &Disabled {
      background-color: $color-background_highlight;
      color: $color-text_paragraph;
    }

    &:hover {
      border: 1px solid $color-border_inputStates;
    }

    &:focus {
      box-shadow: 0px 0px 5px 0px $color-boxshadow_focused;
      border: 1px solid $color-border_inputStates;
    }
  }

  .calendarContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid $color-background_highlight;
    text-transform: uppercase;
    font-style: normal;
    font-weight: $font-weight_normal;
    font-size: 12px;
    line-height: 14px;

    button {
      span::before {
        border-color: $color-red_action;
      }
    }
  }

  .helperText {
    color: $color-text_grey;

    &Error {
      color: $color-text_red;
    }
  }

  .legendContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $gap_xsmall;
    border-top: 1px solid $color-background_emphasis;
    padding: $spacing_xsmall 0;
    text-transform: capitalize;
    svg {
      color: $color-text_green;
    }
  }
}

button {
  color: $color-red_action;
}

.calendarInputContainerError {
  input {
    border: 2px solid $color-red_danger !important;
  }
}
