@import '../../../styles/index.scss';

.parseCSVModalContainer {
  width: $width-mid-container;
  padding: $spacing_xlarge;
  color: $color-text_paragraph;

  h3 {
    margin-bottom: $spacing_xsmall;
  }
}

.parseCSVModalIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-bottom: $spacing_large;
  background-color: $color-background_information;
  border-radius: 100%;

  svg {
    width: 28px;
    height: 28px;
  }

  &__cancel {
    background-color: $color-background_red;
    color: $color-text_red;

    svg {
      position: relative;
      left: 2px;
    }
  }
}

.parseCSVModalProgressContainer {
  padding: $spacing_large 0 $spacing-xsmall;
}

.parseCSVModalProgressBar {
  position: relative;
  width: 100%;
  height: 12px;
  margin-bottom: $spacing_small;
  border-radius: 12px;
  background: #e9edf5;
  overflow: hidden;
}

.parseCSVModalProgressFill {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  background-color: $color-green_successDark;
  border-radius: 12px;
}

.parseCSVModalActions {
  position: relative;
  display: flex;
  margin: $spacing-large 0 calc($spacing_small * -1);
  padding-top: $spacing_medium;
  border-top: 1px solid $color-border_lightGrey;

  & > :first-child {
    position: relative;
    left: calc($spacing_large * -1);
  }
}
//TODO REMOVE
.debug {
  padding-top: $spacing_large;
}
