@import '../../styles/index.scss';

.ctaContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
.ctaWrapper {
  padding: $spacing_small $spacing_large;
  background-color: $color-background_highlight;
  position: relative;
  color: $color-text_black;
  border-radius: $border-radius_small;

  //The call to action button sits above the content card it's refering to.
  &Above {
    margin-bottom: 40px;

    .triangle {
      border-bottom: none;
      border-top: $spacing_small solid $color-background_highlight;
      top: 46px;
    }
  }

  //The call to action button sits below the content card it's refering to.
  &Below {
    margin-top: 32px;

    .triangle {
      top: -10px;
      border-bottom: $spacing_small solid $color-background_highlight;
      border-top: none;
    }
  }

  .triangle {
    position: absolute;
    left: 50%;
    margin-left: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
  }
  .ctaContent {
    font-weight: $font-weight_medium;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: $color-text_black;
    margin: $margin-0;
  }
}
