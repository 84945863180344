.tableContainer {
  width: 100%;
  border: 1px solid $color-border_lightGrey;
  border-radius: $border-radius_small;
  width: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: $color-white;
  margin-top: 16px;

  .table {
    border-top: 1px solid $color-border_lightGrey;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    border-collapse: collapse;

    .th {
      background: $color-background_emphasis;
      text-transform: uppercase;
      color: $color-table_label;
      padding: 13.5px 6px;
      text-align: left;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      border-bottom: 1px solid $color-border_lightGrey;
      vertical-align: middle;
      letter-spacing: -0.35px;

      &:hover {
        cursor: pointer;
        background: $color-background_hoverTableActive;
      }

      &:nth-of-type(1) {
        &::before {
          content: '';
          display: none;
          left: 0;
          height: 0;
          border: 0;
        }
      }

      &Selected {
        background-color: $color-background_highlight;
        color: $color-text_paragraph;
        &:hover {
          color: $color-primary_hover;
          background-color: $color-background_hoverTableActive;
        }
      }
    }

    tr {
      height: 45px;

      &:hover {
        background-color: $color-background_emphasis;
      }
    }

    td {
      border-bottom: 1px solid $color-border_lightGrey;
      max-height: 45px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 4px;
      vertical-align: middle;
      cursor: pointer;

      * {
        letter-spacing: -0.25px;
      }
    }
  }

  .tableFooter {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .tableButtons {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 16px 24px;

    .buttonsLeft {
      display: flex;
      align-items: center;
      gap: 48px;
    }

    .buttonsRight {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }

    .itemsPerPageContainer {
      display: flex;
      align-items: center;
      font-size: 1rem;
      margin: auto;
      gap: 0.5rem;
    }

    .itemsPerPageContainer select {
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 1rem;
      color: #333;
      background-color: #fff;
      cursor: pointer;
      transition: border-color 0.3s ease;
    }
    .itemsPerPageContainer select:hover {
      border-color: #222222;
    }

    .pagination {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  } // .tableButtons

  tr.tableRow:hover {
    background-color: $color-background_emphasis !important;
  }

  tr.tableRowSelected {
    background-color: $color-background_green;
  }

  tr.tableRowDisabled {
    background-color: $color-background_emphasis;

    &:hover {
      background-color: $color-background_emphasis;
      border-left: none;
    }
  }

  .th {
    background: $color-background_emphasis;

    .headerColumnWrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .shortLabel {
        max-width: 50px;
      }

      .mediumLabel {
        max-width: 60px;
      }
    }
  }

  .th .headerColumnWrapper .tooltipContainer {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);

    &Top {
      bottom: 100%;
      transform: translate(-50%, -50%);
    }
    &Bottom {
      top: 100%;
      transform: translate(-50%, 25%);
    }
  }

  .th:hover .tooltipContainer {
    visibility: visible;
  }

  .divContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    label {
      span {
        visibility: visible;
        position: relative;
        left: 0;
      }
    }
  }

  .th {
    height: auto;
    padding-left: 6px;
  }

  .arrowAsc,
  .arrowDesc {
    padding-left: $spacing-xxsmall;
    padding-top: 1.5px;
    cursor: pointer;
    text-align: left;
    display: block;
    display: inline;
  }

  .arrowDesc {
    svg {
      height: 16px;
      width: 8px;
    }
  }

  td.cellContainer {
    overflow: visible;
  }
}

select:focus,
input:focus,
textarea:focus {
  outline: 0;
  outline-color: transparent;
  outline-style: none;
}

input:hover,
textarea:hover {
  border: 1px solid $color-border_inputStates;
}
