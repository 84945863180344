@import '../src/styles/index';

html,
body {
  margin: 0;
  font-family: $text-primary-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-background_emphasis;
  box-sizing: border-box;

  @media screen and (min-width: 1280px) {
    overflow-x: hidden;
  }

  &.ReactModal__Body--open {
    overflow-y: hidden;
  }
}

*,
*:after,
*:before {
  box-sizing: inherit;
  font-family: $text-primary-font-family;
  letter-spacing: -0.5px;
} // *, *:after, *:before

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-weight: $font-weight_normal;
  font-size: 16px;
  line-height: $spacing_large;
  margin: $margin-0;
}

button {
  background: none;
  border: 0;
  outline: none;
}
