@use '../../../styles/components/calculateButton.scss';
@import '../../../styles/index.scss';

.addNewModalContainer {
  display: flex;
  flex-direction: column;
  padding: $spacing_xlarge;
  width: fit-content;

  .addNewModalWrapper {
    display: flex;
    padding-top: $spacing_large;
  }

  .productDetailsWrapper {
    width: 234px;
    padding-right: $spacing_large;
    .row {
      display: flex;
      align-items: center;

      &:first-of-type {
        gap: $gap_xxlarge;
        padding-bottom: 4px;
      }

      &:nth-of-type(2) {
        padding-top: $spacing_xsmall;
        gap: $gap_medium;

        p:nth-child(3) {
          border-left: 1px solid $color-background_highlight;
          padding-left: $spacing_xsmall;
        }
      }

      &:nth-of-type(3) {
        padding-top: $spacing_xsmall;
        gap: $gap_xxlarge;
      }
    }
  }

  .formWrapper {
    padding-left: $spacing_large;
    border-left: 1px solid $color-background_highlight;
    width: 804px;
    max-width: 804px;
    min-width: 804px;
    .formRow {
      display: flex;
      gap: $gap_xxlarge;
      padding-bottom: $spacing_large;
      align-items: flex-end;

      &Copy {
        max-width: 800px;
        margin-bottom: $spacing_large;
      }

      &Currency {
        background-color: $color-background_emphasis;
        flex-wrap: nowrap;
        display: flex;
        gap: $gap_xxlarge;
        padding: $spacing_large $spacing_large $padding-medium;
        align-items: center;
        margin-bottom: $spacing_large;

        button {
          margin-top: 7px;
        }

        &Error {
          padding-bottom: $spacing-large;
        }
      }

      &Currency > * {
        max-width: 20%;
      }

      &Checkbox {
        display: flex;
        flex-wrap: wrap;
        width: 804px;
        gap: $gap_xxlarge;
        background-color: $color-background_emphasis;
        padding: $spacing_large;

        .emailContainer {
          width: 100%;
        }
      }

      &Checkbox > * {
        width: 366px;
      }
    }
  }

  .buttonCalculate {
    height: 100%;
    margin-bottom: 20px;
    // Apply button padding.
    $btn-svg-height: 18px;
    $btn-horizontal-pad: $spacing_small;
    @include calculateButton.getBtnPadding(
      $input-border,
      $input-padding,
      $input-line-height,
      $btn-svg-height,
      $btn-horizontal-pad
    );
  }
}

.buttonContainer {
  display: flex;
  border-top: 1px solid $color-background_highlight;
  padding-top: $spacing_large;
  gap: $gap_xxlarge;
  margin-top: $spacing_large;

  .deleteButton {
    margin-left: auto;
    padding-right: $padding-0;
  }

  .discardButton {
    padding: 0;
  }
}

.otherColumn {
  display: flex;
  flex-direction: column;
  gap: $spacing_large;

  .otherRow {
    display: flex;
    gap: $gap_medium;
    align-items: center;

    svg {
      color: $color-background_mediumGrey;
    }
  }
}

.buttonWarnings {
  display: flex;
  flex-direction: column;
  padding: $spacing-large;
  background-color: $color-background_emphasis;
}

.buttonErrors {
  padding-left: 0;
}
