@import '../../styles/index.scss';
//simple scss file for overidding the react datepicker custom styles
.react-datepicker__day--highlighted {
  border: 1px solid $color-text_green;
  border-radius: 50%;
  background-color: $color-white;
}

.react-datepicker__day--highlighted:hover {
  background-color: $color-background_emphasis;
  border: 1px solid $color-text_green;
  border-radius: 50%;
}

.react-datepicker__header {
  background-color: $color-white;
  border-bottom: 0;
}

.react-datepicker__current-month {
  text-transform: capitalize;
  padding-bottom: 14px;
  color: $color-headings_black;
  padding-top: 16px;
}

.react-datepicker__week {
  min-height: 43px;
  display: flex;
  align-items: center;
}

.react-datepicker__day-names {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $color-text_paragraph;
  display: flex;
  justify-content: space-evenly;
}

.react-datepicker__day {
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  color: $color-text_paragraph;
  margin: 0.166rem;
  width: 40px;
  height: 40px;
  text-align: center;
}

.react-datepicker__day--disabled {
  color: $color-text_Light;
}

.react-datepicker__navigation {
  top: 20px;
}

.react-datepicker__input-container {
  input::placeholder {
    color: $color-text_placeholder;
  }
}

.react-datepicker__year-dropdown
  .react-datepicker__year-option:first-child::before {
  content: '\2B9D';
  position: absolute;
  top: 5%;
  left: 45%;
  font-size: large;
  margin: auto;
}

.react-datepicker__year-dropdown::after {
  content: '\2B9F';
  position: absolute;
  left: 45%;
  bottom: 5%;
  font-size: large;
  margin: auto;
}

.react-datepicker__tab-loop {
  margin-top: -6px;
}

.react-datepicker-wrapper {
  width: inherit;
}
