@import '../../styles/index.scss';

.container {
  padding-bottom: $spacing_large;
  margin: 0 0 $spacing_medium;
  border-bottom: 1px solid $color-border_lightGrey;
  word-wrap: break-word;
}

.sectionHeading {
  margin-bottom: $spacing_large;
}

.announcementDate {
  margin-bottom: $spacing_xsmall;
  color: $color-text_paragraph;
}

.announcementContent > * + * {
  margin-top: $spacing_small;
}

.announcementContent p:first-of-type {
  font-size: 12px;
  margin-top: -0.75rem;
}

.announcementContent p {
  color: $color-text_paragraph;
  line-height: 24px;
  max-width: 580px;
}

.announcementContent h2,
.announcementContent h3,
.announcementContent h4,
.announcementContent h5 {
  font-weight: 500;
}

.noResults {
  padding: $spacing_large;
  text-align: center;
  background-color: $color-background_lightGrey;
  color: $color-text_paragraph;
  width: 100%;
  border-radius: $border-radius_small;
}
