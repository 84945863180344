@import '../../styles/index.scss';

.helperText {
  color: $color-text_grey;

  &Error {
    color: $color-red_danger;
  }
}

.textAreaContainer {
  display: flex;
  flex-direction: column;
  gap: $gap_small;
  width: 100%;
  align-items: flex-start;

  .textContainer {
    height: 14px;
  }

  .textarea {
    border: 1px solid $color-background_mediumGrey;
    border-radius: $border-radius_xsmall;
    padding: 9px $padding_medium;
    font-style: normal;
    font-weight: $font-weight_normal;
    font-size: 14px;
    line-height: 22px;
    color: $color-text_black;
    width: 100%;
    resize: none;

    &::placeholder {
      color: $color-text_black;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $color-text_black;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $color-text_black;
    }

    &Error {
      border: 2px solid $color-red_danger !important;
    }

    &Disabled {
      background-color: $color-background_highlight;
      border-color: $color-background_highlight;
      color: $color-text_paragraph;

      &::placeholder {
        color: $color-text_paragraph;
      }
    }

    &:hover {
      border: 1px solid $color-border_inputStates;
    }

    &:focus {
      box-shadow: 0px 0px 5px 0px $color-boxshadow_focused;
      border: 1px solid $color-border_inputStates;
    }
  }
}
