@import '../../styles/index.scss';

.selectInputContainer {
  display: flex;
  flex-direction: column;
  gap: $gap_small;
  width: 100%;

  * {
    font-family: $text-primary-font-family;
  }

  &Large,
  &DoubleColumn {
    max-width: 390px;
    width: 390px;
    min-width: 390px;
  }

  &SingleColumn {
    width: 183px;
  }

  .helperText {
    color: $color-text_grey;

    &Error {
      color: $color-text_red;
    }
  }

  .textContainer {
    height: 14px;
  }

  input:hover {
    cursor: pointer;
  }
}
