@import '../../styles/index.scss';

.dateSelectMenuContainer {
  width: 275px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  gap: $gap_small;

  * {
    font-family: $text-primary-font-family;
  }

  .helperText {
    color: $color-text_grey;

    &Error {
      color: $color-text_red;
    }
  }

  &DoubleColumn {
    max-width: 390px;
    width: 390px;
  }

  &SingleColumn {
    width: 183px;
  }
}

.groupHeadingContainer,
.optionContainer {
  display: flex;
  justify-content: space-between;
}

.groupHeadingContainer {
  padding: $spacing-xxsmall $spacing_xsmall;
}

.headingText {
  font-style: normal;
  font-weight: $font-weight_medium;
  font-size: 14px;
  line-height: 20px;
  color: $color-headings_black;
  background-color: $color-white;
  width: 50%;
}

.optionContainer {
  background-color: $color-white;
  position: relative;
  padding: $spacing-xxsmall $spacing_xsmall;
  color: $color-text_paragraph;

  &Disabled {
    color: $color-text_placeholder;
    background-color: $color-background_highlight;
    border-radius: $border-radius_xsmall;
  }

  &Selected {
    color: $color-white;
    background-color: $color-primary_base;
    border-radius: $border-radius_xsmall;
  }

  &Focused {
    color: $color-primary_hover;
    background-color: $color-background_emphasis;
    border-radius: $border-radius_xsmall;
    cursor: pointer;
  }
}

.optionWrapper {
  display: flex;
  justify-content: space-between;
  background-color: inherit;
  color: inherit;
  flex-wrap: nowrap;
  width: 100%;

  .optionText {
    color: inherit;
    background-color: inherit;
    width: 50%;
  }
}

.icon {
  position: absolute;
  right: $spacing_xsmall;
  svg {
    height: $spacing_small;
    width: auto;
  }
}
