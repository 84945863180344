@import '../../styles/index.scss';

.helperText {
  color: $color-text_grey;

  &Error {
    color: $color-red_danger;
  }
}

.textInputContainer {
  display: flex;
  flex-direction: column;
  gap: $gap_small;
  width: 100%;
  align-items: flex-start;

  &Hidden {
    display: none;
  }

  &Medium {
    width: 183px;
  }

  &Large {
    width: 230px;
  }

  &DoubleColumn {
    max-width: 390px;
    width: 390px;
  }

  &SingleColumn {
    width: 183px;
  }

  .textContainer {
    height: 14px;
  }

  /* Remove the default arrows */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .inputWrapper {
    display: flex;
    border: $input-border solid $color-background_mediumGrey;
    border-radius: $border-radius_xsmall;
    line-height: $input-line-height;
    font-style: normal;
    font-weight: $font-weight_normal;
    font-size: 14px;
    line-height: $input-line-height;
    color: $color-text_black;
    width: inherit;
    width: 100%;
    overflow: visible;

    &::placeholder {
      color: $color-text_black;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $color-text_black;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $color-text_black;
    }

    &:hover {
      border: 1px solid $color-border_inputStates;
    }

    &:focus-within {
      box-shadow: 0px 0px 5px 0px $color-boxshadow_focused;
      border: 1px solid $color-border_inputStates;
    }

    &Error {
      border: 2px solid $color-red_danger !important;
    }

    &Disabled {
      background-color: $color-background_highlight;
      border-color: $color-background_highlight;
      color: $color-text_paragraph;

      &::placeholder {
        color: $color-text_paragraph;
      }
    }
  }
  .prefix {
    position: relative;
    padding: $input-padding 0 $input-padding $padding_medium;
    font-style: normal;
    font-weight: $font-weight_normal;
    font-size: 14px;
    line-height: $input-line-height;
    color: $color-text_black;
  }
  .input {
    padding: 0px;
    border: $input-border none $color-background_mediumGrey;
    border-radius: $border-radius_xsmall;
    font-style: normal;
    font-weight: $font-weight_normal;
    font-size: 14px;
    line-height: $input-line-height;
    color: $color-text_black;
    width: inherit;
    width: 100%;

    &::placeholder {
      color: $color-text_black;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $color-text_black;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $color-text_black;
    }

    &Error {
      border: 2px solid $color-red_danger !important;
    }

    &Disabled {
      background-color: $color-background_highlight;
      border-color: $color-background_highlight;
      color: $color-text_paragraph;

      &::placeholder {
        color: $color-text_paragraph;
      }
    }
  }

  .innerInput {
    width: inherit;
    border: none;

    &Disabled {
      background-color: $color-background_highlight;
      border-color: $color-background_highlight;
      color: $color-text_paragraph;

      &::placeholder {
        color: $color-text_paragraph;
      }
    }
  }
}
