@import '../../styles/index.scss';

.homeContact {
  margin-bottom: calc($spacing_xlarge * -0.9);
  padding: $spacing_medium 0 0;
  text-align: center;
}

.homeContactTitle {
  margin-bottom: $spacing_medium;
}

.homeContactText {
  .homeContactText {
    margin-top: $spacing-small;
    p {
      margin: $spacing-xsmall 0 $spacing-xsmall;
    }
  }
}

.homeContactText p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.5px;
  font-weight: 400;
  color: $color-text_paragraph;
}

.homeContactText a {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  color: $color-red_action;
}
