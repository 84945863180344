@import '../../styles/index.scss';

.tblTooltip {
  background: $color-black;
  color: $color-white;
  font-weight: $font-weight_normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius_small;
  padding: 6px $spacing_xsmall;
  min-width: max-content;
  position: relative;
  margin-top: 5px;
  margin-left: -10px;
  white-space: nowrap;

  .tblTooltipLabel {
    text-transform: none;
    white-space: nowrap;
    overflow-wrap: break-word;
    text-align: center;
    font-size: 12px;
    max-width: 600px;

    &BigText {
      white-space: initial;
    }
  }

  .tblTooltipArrow {
    position: absolute;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    &Bottom {
      border-bottom: $spacing_small solid $color-black;
      border-top: 0;
      top: -10px;
    }

    &Top {
      border-bottom: 0;
      border-top: $spacing_small solid $color-black;
      bottom: -10px;
    }
  }
}
