@import '../../styles/index.scss';

.button {
  padding: $spacing_xsmall $spacing_medium;
  color: $color-white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: $gap_large;
  border-radius: $border-radius_xsmall;
  font-style: normal;
  font-weight: $font-weight_medium;
  font-size: 16px;
  line-height: $spacing_large;
  max-height: $spacing_xlarge;
  background: none;
  border: 0;
  outline: none;
  max-height: 40px;

  &:hover {
    cursor: pointer;
  }

  &Primary {
    background-color: $color-primary_base;
    color: $color-white;

    &:hover {
      background-color: $color-primary_hover;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &Disabled {
      background-color: $color-background_mediumGrey;
      border: none;

      &:hover {
        background-color: $color-background_mediumGrey;
        box-shadow: none;
      }
    }
  }

  &Secondary {
    border: 1px solid $color-primary_base;
    background-color: $color-white;
    color: $color-primary_base;
    padding: $spacing_xsmall $spacing_large;

    &:hover {
      background-color: $color-white;
      color: $color-primary_hover;
      border: 1px solid $color-primary_hover;
      box-shadow: 0px 4px 4px #00000040;
    }

    &Disabled {
      border: 1px solid $color-background_mediumGrey;
      color: $color-background_mediumGrey;
      background: $color-white;
      &:hover {
        background-color: $color-white;
        box-shadow: none;
        border: 1px solid $color-background_mediumGrey;
        color: $color-background_mediumGrey;
      }
    }
  }

  &Link {
    background-color: inherit;
    color: $color-red_action;
    border: none;

    &:hover {
      color: $color-red_hover;
      background-color: none;
      border: none;
    }

    &Small {
      font-size: 12px;
      line-height: 14px;
      font-weight: $font-weight_medium;
      padding: 0;
    }

    &Disabled {
      color: $color-red_disabled;
      &:hover {
        color: $color-red_disabled;
      }
    }
  }

  &IconPagination {
    background-color: inherit;
    color: $color-primary_base;
    border: none;
    min-width: 0;
    padding: $padding-0 $padding_medium;

    &Disabled {
      color: $color-grey_disabled;
    }
  }

  &Pagination {
    color: $color-primary_base;
    background-color: inherit;
    border: 0;
    max-height: 32px;
    max-width: 32px;
    min-width: 0;
    padding: $spacing-xxsmall $padding_medium;
    margin: $margin-0 $spacing-xxsmall;

    &:hover,
    &Active {
      color: $color-white;
      background-color: $color-primary_hover;
      border-radius: $border-radius_xsmall;
    }
  }

  &Action {
    background: none;
    color: $color-red_action;
    font-style: normal;
    font-weight: $font-weight_medium;
    font-size: 12px;
    line-height: 14px;
    padding: $padding-0;

    &:hover {
      color: $color-red_hover;
      background-color: $color-white;
      border: 0;
      box-shadow: none;
    }
  }

  &Warning {
    background: $color-red_action;
    color: $color-white;

    &:hover {
      color: $color-white;
      background-color: $color-red_hover;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &Disabled {
      background: $color-background_mediumGrey;
      border: none;

      &:hover {
        background: $color-background_mediumGrey;
        box-shadow: none;
      }
    }
  }

  &WarningDark {
    background: $color-orange_warningDark;
    color: $color-white;

    &:hover {
      color: $color-white;
      background-color: $color-orange_warningDark_hover;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &Disabled {
      background: $color-background_mediumGrey;
      border: none;

      &:hover {
        background: $color-background_mediumGrey;
        box-shadow: none;
      }
    }
  }

  &Success {
    background: $color-green_successDark;
    color: $color-white;

    &:hover {
      color: $color-white;
      background-color: $color-green_successDark_hover;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &Disabled {
      background: $color-background_mediumGrey;
      border: none;

      &:hover {
        background: $color-background_mediumGrey;
        box-shadow: none;
      }
    }
  }

  &PadHelper {
    margin-bottom: 20px;
  }

  &NoPad {
    padding-left: 0;
    padding-right: 0;
  }

  .iconRight,
  .iconLeft {
    display: flex;
  }
}

.button {
  &Disabled {
    &:hover {
      cursor: default;
    }
  }
}
