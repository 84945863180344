@import '../../styles/index.scss';

.collapseContainer {
  background: $color-background_emphasis;
  border-radius: $border-radius_medium;
  margin-bottom: $spacing_large;
  padding: $spacing_medium $spacing_large;
  color: $color-text_paragraph;
}

.collapseButton {
  cursor: pointer;
  color: $color-red_action;
  font-weight: $font-weight_medium;
  display: flex;
  gap: $gap_medium;
  align-items: center;
  margin: 0;
  padding: 0;
}

.collapseButton svg {
  width: 14px;
  height: 14px;
  opacity: 0.9;
  transition: transform 0.1s ease-in-out;
}

.collapseButton[aria-expanded='true'] svg {
  transform: rotate(-90deg);
}

.collapseContent {
  margin-top: $spacing-xxsmall;
  padding: $spacing_medium 0 0;

  p {
    line-height: 14px;
    letter-spacing: -0.125px;
  }
}

.collapseContent[aria-hidden='true'] {
  display: none;
  margin-top: $spacing_medium;
}

.collapseContent > * + * {
  margin-top: $spacing_medium;
}
