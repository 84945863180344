@import '../../styles/index.scss';

.bannerContainer {
  padding: $spacing_medium;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: $border-radius_medium;
  margin-bottom: $spacing_large;
  position: relative;
  cursor: pointer;

  &Error {
    color: $color-white;
    background-color: $color-red_danger;

    .bannerHeading {
      color: $color-white;
    }
  }

  &ErrorOutline {
    color: $color-white;
    background-color: $color-background_red;
    border: $color-text_red 1px solid;
    color: $color-text_red;
    cursor: initial;

    .bannerHeading {
      color: $color-text_red;
    }
  }

  &Info {
    color: $color-text_information;
    background-color: $color-background_information;
    border: 1px solid $color-border_information;

    .bannerHeading {
      color: $color-text_information;
    }
  }

  &Success {
    color: $color-text_green;
    background-color: $color-background_green;
    border: 1px solid $color-text_green;

    .bannerHeading {
      color: $color-text_green;
    }
  }

  &Warning {
    color: $color-orange_warningDark;
    background-color: $color-orange-warningLight;
    border: 1px solid $color-orange_warningDark;

    .bannerHeading {
      color: $color-orange_warningDark;
    }
  }

  &TopAlign {
    .bannerIcon {
      margin-top: 0 !important;
    }
  }

  &CenterAlign {
    align-items: center;
  }

  &Small {
    padding: $spacing_small;
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .bannerIcon {
    margin-right: 17px;
    margin-top: 6px;
  }

  .bannerIconCalculator {
    height: 18px;
    margin-right: 8px;
    margin-top: 0;
  }

  .closeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 14px;
    top: 7px;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  .underline {
    margin-top: $spacing_xsmall;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;

    &:hover {
      text-decoration: none;
    }
  }
}
