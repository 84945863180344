@import '../../styles/index.scss';
@import '../../styles/components/table';

.priceRequestListTable {
  .tableContainer .table {
    thead th {
      height: 54px;
    }
    .th,
    td {
      &:nth-of-type(1) {
        max-width: 60px;
      }

      &:nth-of-type(2) {
        max-width: 100px;
      }

      &:nth-of-type(3) {
        max-width: 83px;
      }

      &:nth-of-type(4) {
        max-width: 93px;
      }

      &:nth-of-type(5),
      &:nth-of-type(6) {
        max-width: 88px;
      }

      &:nth-of-type(7) {
        max-width: 50px;
      }

      &:nth-of-type(8),
      &:nth-of-type(9) {
        max-width: 88px;
      }

      &:nth-of-type(10),
      &:nth-of-type(11),
      &:nth-of-type(12),
      &:nth-of-type(13),
      &:nth-of-type(14) {
        max-width: 80px;
        min-width: fit-content;
      }

      &:nth-of-type(15),
      &:nth-of-type(16) {
        max-width: 68px;
      }
    }
  }

  .tableContainer {
    margin-top: $margin-0;
  }

  .apiCollectionForm {
    display: flex;
    flex-direction: row;
    gap: 20px;
    max-width: fit-content;
    margin-bottom: 16px;
    border: 1px solid $color-border_lightGrey;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 24px;
    border-radius: $border-radius_small;
    background-color: $color-white;
  }

  .input {
    flex: 1 0 18%;

    &Buttons {
      display: flex;
      align-items: flex-end;
      height: 68px;
      flex: 1 0 18%;

      button {
        height: 40px;
        padding-left: $spacing_large;
        padding-right: $spacing_large;
      }

      &Internal {
        flex: unset;
        margin-left: auto;
        height: auto;
        max-width: 213px;
        flex: 1 0 18%;

        button:nth-of-type(1) {
          padding-left: $spacing_large;
          padding-right: $spacing_large;
        }
      }
    }

    &calendar {
      > div {
        max-width: none;
        height: 70px;
      }
    }
  }

  .internalClearButtons {
    margin-right: -2.5rem;
    button:nth-of-type(1) {
      padding-left: $spacing_large;
      padding-right: $spacing_large;
    }
  }

  .externalClearButtons {
    margin-right: -2rem;
    button:nth-of-type(1) {
      padding-left: $spacing_large;
      padding-right: $spacing_large;
    }
  }

  .filterToggleContainer {
    padding: 16px 32px 0;

    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      & > button {
        margin-left: -1rem;
      }
    }
    .tableButtons {
      padding: 0;
    }
  }

  .filterToggleContainerActive {
    padding: 16px 32px 32px;
  }

  .filterContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  input[type='reset'] {
    padding: 12px 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    background-color: $color-white;
    color: $color-red_action;
    border: none;
    cursor: pointer;

    &:hover {
      color: $color-red_hover;
    }
  }

  .searchLayout {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  .searchDetailsContainer {
    padding: 12px 24px;
    background-color: lightgray;
    position: relative;
    color: $color-text_black;
    border-radius: $border-radius_small;

    .triangle {
      position: absolute;
      top: -10px;
      left: 50%;
      margin-left: -20px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 12px solid lightgray;
    }

    .searchDetails {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: $color-text_black;
      margin: $margin-0;
    }
  }

  .searchErrorContainer {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      margin-bottom: 8px;
    }
    .iconContainer {
      background-color: $color-orange_warningLight;
      height: 60px;
      width: 60px;
      border-radius: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;

      svg {
        color: $color_orange_warningDark;
        height: 25px;
        width: 25px;
      }
    }
  }

  .alertBanner {
    margin-top: 16px;
  }
}
