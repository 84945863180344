@import '../../styles/index.scss';

.modalContainer {
  position: relative;
  top: 0;
  left: 0;
  margin: 50px auto;
  max-width: fit-content;
  min-width: fit-content;
  width: auto;
  border: 1px solid $color-background_highlight;
  background: $color-white;
  gap: $gap_xlarge;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius_large;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: $z-index_modal;
  // Adding below to help with blurry text that happens
  // when height or top values become a non-integer
  min-height: 10vh;
  max-height: calc(95vh - 100px);
  -webkit-font-smoothing: antialiased;
  -webkit-filter: blur(0px);
  filter: blur(0px);
  backface-visibility: hidden;
  backdrop-filter: blur(0rem);

  &Alert {
    border-top: 6px solid $color-text_red;
  }

  &Information {
    border-top: 6px solid $color-text_information;
  }

  .closeContainer {
    position: absolute;
    top: 18px;
    right: 18px;
    color: #143252;
    cursor: pointer;
    z-index: 100;
  }
}

.overlayContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: $z-index_overlay;
}
