// Brand or Action Colours
$color-blue_robin: rgba(0, 152, 205, 0.15);
$color-primary_base: #143252;
$color-primary_hover: #07111c;
$color-white: #ffffff;
$color-grey_disabled: #c4c4c4;
$color-black: #000000;
$color-red_action: #af2841;
$color-red_danger: #f55b5b;
$color-red_hover: #7d1d2e;
$color-red_disabled: #e1bac4;
$color-orange_warning: #fdaa29;
$color-orange-warningLight: #fcf2e6;
$color-orange_warningDark: #aa5b00;
$color-orange_warningDark_hover: darken($color-orange_warningDark, 5);
$color-green_successDark: #14804a;
$color-green_successDark_hover: darken($color-green_successDark, 10);

// Text colours
$color-text_red: #d1293d;
$color-text_yellow: #aa5b00;
$color-text_green: $color-green_successDark;
$color-text_purple: #5e5adb;
$color-text_highlight: #5a6376;
$color-text_paragraph: #464f60;
$color-text_grey: #60686f;
$color-text_grey: #697178;
$color-text_black: #212121;
$color-text_Light: #868fa0;
$color-text_placeholder: #a1a9b8;
$color-text_information: #2264e5;
$color-headings_black: #171c26;

// Background colours
$color-background_red: #ffedef;
$color-background_emphasis: #f7f9fc;
$color-background_lightGrey: #f7fafd;
$color-background_highlight: #e9edf5;
$color-background_green: #e1fcef;
// $color-background_green: rgba(39, 174, 96, 0.1);
$color-background_purple: #ededfc;
$color-background_yellow: #fcf2e6;
$color-background_grey: #f8f8f8;
$color-background_Lightgrey: #e5e5e5;
$color-background_mediumGrey: #bcc2ce;
$color-background_information: #ebf0fa;
$color-background-avatar: #0098cd;
$color-background_hoverTableActive: #f0f5fc;

// Border Colors
$color-border_lightGrey: #eae9ea;
$color-border_mediumGrey: #c4c4c4;
$color-border_information: #2264e5;
$color-border_inputStates: #687182;

$color-boxshadow_focused: #4a90e2;

// Label Colors
$color-table_label: #687182;
