@import '../../styles/index.scss';

.headerContainer {
  background: $color-primary_base;
  display: flex;
  justify-content: center;
  position: fixed;
  font-family: $text-primary-font-family;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-index_header;

  .headerWrapper {
    width: 100%;
    margin: $margin-0 $spacing_large;

    .headerTitle {
      margin-top: 23px;
      margin-bottom: 27px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .headerLogoContainer {
        display: flex;
        flex-direction: row;
        gap: $gap_xlarge;
        align-items: center;
      }

      .headerUserContainer {
        display: flex;
        flex-direction: row;
        width: auto;
        height: 40px;
        gap: $gap_xlarge;
        padding-right: $spacing_large;
        align-items: center;

        .headerUserAvatar {
          background-color: $color-background-avatar;
          border-radius: 50%;
          min-height: $avatar_size;
          text-align: center;
          min-width: $avatar_size;
          color: $color-white;

          span {
            font-size: calc($avatar_size / 2); /* 50% of parent */
            line-height: 1;
            position: relative;
            top: calc($avatar_size / 4); /* 25% of parent */
          }
        }

        .headerUserDetails {
          display: flex;
          flex-direction: column;
          gap: $gap_xsmall;

          span {
            color: $color-white;
            /* Paragraph/Small Strong */
            font-size: 14px;
            font-family: $text-primary-font-family;
            font-weight: 400;
            line-height: 14px;
          }

          .bold {
            font-weight: 700;
          }
        }
      }
    }
    .navContainer {
      position: relative;
    }
    .navWrapper {
      margin-left: $spacing_large;
      display: flex;
      justify-content: flex-start;
    }
    .authenticationContainer {
      position: absolute;
      right: 0;
      bottom: 10px;
      &.authenticated {
        bottom: 0;
      }
    }
  }
}

.userLink {
  font-size: inherit;
  display: inherit;
  font-style: inherit;
  text-decoration: none;
}
