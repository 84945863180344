// currently the WPM is being built for desktop screens, with a minimum width of 1280px
// This version of the WPM is not being built for mobile or tablet screens

$breakpoints: (
  base: 0,
  desktop: 1280px,
  desktoplarge: 1440px
);

// generate our breakpoint mixins
@mixin breakpoint($breakpoint, $useMax: false) {
  $size: map-get($breakpoints, $breakpoint);
  $breakpoint-index: index(map-keys($breakpoints), $breakpoint);
  @if $useMax == true and $breakpointIndex < length($breakpoints) {
    $max-size: nth(map-values($breakpoints), $breakpointIndex + 1) - 1;
    @media screen and (min-width: $size) and (max-width: $maxSize) {
      @content;
    }
  } @else {
    @include breakpoint-on-value($size) {
      @content;
    }
  }
}

@mixin breakpoint-on-value($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}
