@import '../../styles/index.scss';

.pillContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius_small;
  padding: $spacing-xxsmall 6px;
  max-width: fit-content;
  text-transform: capitalize;

  td & {
    margin: $margin-0 auto;
  }

  &Large {
    padding: $spacing_xsmall $spacing_small;
  }

  &XLarge {
    padding: $spacing_small;

    p {
      font-size: 16px;
    }
  }

  &Bold {
    font-weight: bold;
  }

  &Success {
    background-color: $color-background_green;
    border: 1px solid $color-background_green;
    color: $color-text_green;

    &Secondary {
      background-color: $color-white;
      border: 1px solid $color-text_green;
    }
  }

  &Error {
    background-color: $color-background_red;
    border: 1px solid $color-background_red;
    color: $color-text_red;

    &Secondary {
      background-color: $color-white;
      border: 1px solid $color-text_red;
    }
  }

  &Neutral {
    background-color: $color-background_highlight;
    border: 1px solid $color-background_highlight;
    color: $color-text_highlight;

    &Secondary {
      background-color: inherit;
      border: 1px solid $color-text_highlight;
    }
  }

  &Inactive {
    background-color: $color-background_highlight;
    border: 1px solid $color-background_highlight;
    color: $color-text_Light;

    &Secondary {
      background-color: $color-white;
      border: 1px solid $color-text_Light;
    }
  }

  &Outline {
    background-color: $color-white;
    border: 1px solid $color-text_Light;
    color: $color-text_Light;
  }

  &Active {
    background-color: $color-background_purple;
    border: 1px solid $color-background_purple;
    color: $color-text_purple;

    &Secondary {
      background-color: $color-white;
      border: 1px solid $color-text_purple;
    }
  }

  &Warning {
    background-color: $color-background_yellow;
    color: $color-text_yellow;
    border: 1px solid $color-background_yellow;

    &Secondary {
      background-color: $color-white;
      border: 1px solid $color-text_yellow;
    }
  }

  &Date {
    background-color: $color-background_highlight;
    color: $color-text_paragraph;
    min-width: 80px;
  }

  &Information {
    background-color: $color-background_information;
    border: 1px solid $color-background_information;
    color: $color-text_information;

    &Secondary {
      background-color: $color-white;
      border: 1px solid $color-text_information;
    }
  }

  &Disabled {
    background-color: $color-white;
    border: 1px solid $color-white;
    color: $color-text_placeholder;
  }
}

.pillContainer > :first-child {
  p {
    white-space: nowrap;
  }
}
