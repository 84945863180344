@function getBtnHeightPlusPadding(
  $input-border: 1px,
  $input-padding: 1px,
  $input-line-height: 22px
) {
  // To calculate Padding for Button.
  //   button[svg_heigh + border{top+btm} + padding{top+btm}] == input[line_heigh + border{top+btm} + padding{top+btm}]
  //   => button[padding] == (input[line_heigh + border{top+btm} + padding{top+btm}] - button[svg_heigh + border{top+btm}]) / 2
  // From button styling.
  $btn-brd-top: $input-border;
  $btn-brd-btm: $input-border;
  // From input styling.
  $inp-brd-top: $input-border;
  $inp-brd-btm: $input-border;
  $inp-pad-top: $input-padding;
  $inp-pad-btm: $input-padding;
  $inp-line-height: $input-line-height;
  // Calculate input height.
  $input-height: (
    $inp-line-height + ($inp-brd-top + $inp-brd-btm) +
      ($inp-pad-top + $inp-pad-btm)
  );
  // Calulate Button's vertical height + padding difference against input's (i.e. height to match).
  $btn-vertical-height-with-pad: (
    $input-height - ($btn-brd-top + $btn-brd-btm)
  );
  @return $btn-vertical-height-with-pad;
}

@mixin getBtnPadding(
  $input-border: 1px,
  $input-padding: 1px,
  $input-line-height: 22px,
  $btn-svg-height: 18px,
  $btn-horizontal-pad: 12px
) {
  // Calulate Button's vertical height + padding difference against input's (i.e. height to match).
  $btn-vert-height-plus-pad: getBtnHeightPlusPadding(
    $input-border,
    $input-padding,
    $input-line-height
  );
  // Calculate Button's Padding difference required to meet input's (i.e. height to match) padding.
  $btn-vert-pad-diff: ($btn-vert-height-plus-pad - $btn-svg-height);

  // Return the final Button's padding.
  padding: calc($btn-vert-pad-diff / 2) $btn-horizontal-pad;
  // Adjust box-sizing so previous padding works properly.
  box-sizing: content-box;
}
