@import '../../../styles/index.scss';

.priceRequestDetailsModalContainer {
  position: relative;
  padding: $spacing_xlarge;
  width: 100vw;
  max-width: $width-full-container;

  &__upper {
    padding: 0 0 $spacing_large;
    border-bottom: 1px solid $color-border_lightGrey;
  }

  &__lower {
    display: flex;
    padding-top: $spacing_large;

    &Left {
      width: $width-modal-sidebar;
      padding: $spacing_xsmall $spacing_large $spacing_xsmall 0;
    }

    &Right {
      flex: 1;
      border-left: 1px solid $color-border_lightGrey;
      padding: $spacing_xsmall 0 $spacing_xsmall $spacing_large;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: $spacing_large 0 $spacing_large $spacing_large;
    border-top: 1px solid $color-border_lightGrey;
  }

  &__tabs {
    display: flex;
    width: 100%;
    padding: $spacing_xlarge 0;
  }
}
