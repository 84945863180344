@import '../../styles/index.scss';

.checkboxInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $input-padding $spacing_xsmall;
  gap: $gap_medium;
  position: relative;
  background: $color-white;
  border: 1px solid $color-background_mediumGrey;
  border-radius: $border-radius_xsmall;
  min-width: fit-content;

  /* Hide the browser's default checkbox */
  .checkboxInput {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-text_grey;
    border-radius: 3px;
    text-align: center;
    left: $spacing_xsmall;

    svg {
      height: $spacing_xsmall;
      color: $color-white;
    }

    svg {
      height: $spacing_xsmall;
      color: $color-white;
    }

    &Selected {
      background-color: $color-text_green;
      border-color: $color-text_green;
    }

    &Disabled {
      border-color: $color-background_mediumGrey;
      background-color: $color-background_mediumGrey;
      pointer-events: none;

      &:hover {
        pointer-events: none;
      }
    }
  }

  .label {
    padding-left: 28px;
  }
}
