@import '../../styles/index.scss';

.scrollUpButton {
  display: flex;
  align-content: center;
  gap: $spacing_xsmall;
  cursor: pointer;
  color: $color-red_action;
  padding: 0;
  align-items: center;
  font-weight: $font-weight_medium;

  svg {
    width: 16px;
    height: 16px;
  }
}
