@import '../../styles/index.scss';

.tablist {
  display: flex;
  flex-direction: row;
  gap: $spacing_large;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid $color-background_highlight;
}

.tablist > li {
  list-style: none;
  padding: 0;
  margin: $margin-0;
}

.tabsWrapper {
  width: 100%;
  border: none;
}

.switcher > * {
  display: flex;
  flex-wrap: wrap;
}

.tabButton {
  color: $color-primary_base;
  font-family: $text-primary-font-family;
  font-size: 16px;
  line-height: $spacing_large;
  font-weight: 500;
  padding: 0 0 $spacing_xsmall;
  margin: $margin-0;
  cursor: pointer;

  &Active {
    cursor: initial;
    color: $color-primary_hover;
    border-bottom: 4px solid $color-primary_hover;
  }
}

.tabPanel {
  display: block;
  &Hidden {
    display: none;
  }
}
