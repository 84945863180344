@import "../../styles/index.scss";

.cardContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .cardWrapper{
    background-color: $color-white;
    padding: $spacing_large;
    border: 1px solid $color-border_lightGrey;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius_small;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap_xlarge;
    width: 100%;
    min-width: fit-content;
  
    &Small {
      max-width: 568px;
    }
  
    &Medium {
      max-width: 768px;
    }
  
    &Large {
      max-width: 1024px;
    }
  
    &Xlarge {
      max-width: calc(1280px - $spacing_xlarge);
    }
  }
}
