@import '../../styles/index.scss';

.overlayContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $color-white;
  z-index: $z-index_loadingOverlay;

  .loaderTitle {
    color: $color-white;
  }
}
