@import '../../styles/index.scss';

.priceRequestBreakdownUpper {
  padding-top: $spacing_large;
  display: flex;
  gap: $spacing_large;
  .priceRequestBreakdownTable {
    width: 100%;
  }
  .priceRequestBreakDownItem {
    border-right: 1px solid $color-background_highlight;
    padding-right: $spacing_large;
    margin-bottom: $spacing_large;

    .headlineValueUppercase {
      text-transform: uppercase;
    }
  }

  .priceBreakDownItem:last-of-type {
    border-right: none;
  }
}

.tableContainer {
  width: 100%;
  table-layout: auto;
  display: table;
  border-collapse: collapse;

  .tableHeader {
    width: 100%;
    background-color: $color-background_emphasis;
    table-layout: auto;
    display: table-header-group;
    .headerRow {
      display: flex;
      justify-content: flex-end;
      gap: $gap_xlarge;
      background: $color-background_emphasis;
      padding: $padding_medium;
      width: 100%;
      border-top: 1px solid $color-background_highlight;
      display: table-row;

      th {
        color: $color-table_label;
        text-transform: uppercase;
        font-family: $text-primary-font-family;
        font-weight: $font-weight_normal;
        font-size: 10px;
        line-height: 13px;
        padding: $padding_medium;
      }
      th:first-of-type {
        position: absolute;
        left: -10000px;
        top: auto;
        width: calc(100% - 240px);
        height: 1px;
        overflow: hidden;
      }
    }
  }

  .bodyRow {
    padding: $padding_medium;
    display: table-row;
    td {
      color: $color-text_paragraph;
      border-top: 1px solid $color-background_highlight;
      padding: $padding_medium;

      &:nth-of-type(2),
      &:nth-of-type(3) {
        text-align: end;
      }
    }
  }

  td,
  th {
    width: 110px;
  }
}
