@import '../../../styles/index.scss';

.confirmProductSearch {
  width: $width-mid-container;
  padding: $spacing_xlarge;
  color: $color-text_paragraph;

  h3 {
    margin-bottom: $spacing_medium;
  }
}

.confirmProductSearchIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-bottom: $spacing_large;
  border-radius: 100%;
  background-color: $color-background_red;
  color: $color-text_red;

  svg {
    width: 28px;
    height: 28px;
    position: relative;
    left: 2px;
  }
}

.confirmProductSearchActions {
  display: flex;
  gap: $spacing_large;
  margin-top: $spacing_large;
  padding-top: $spacing_large;
  border-top: 1px solid $color-border_lightGrey;

  button:first-child {
    margin-left: calc($spacing_large * -1);
  }
}
