@import '../../styles/index.scss';
@import '../../styles/components/table';

.productSearchTable .tableContainer .table {
  th {
    &:first-of-type {
      padding: 16px;
    }
  }
  .th,
  td {
    &:nth-of-type(1) {
      max-width: 40px;
    }

    &:nth-of-type(2) {
      max-width: 50px;
    }

    &:nth-of-type(3) {
      max-width: 175px;
    }

    &:nth-of-type(4) {
      max-width: 80px;
    }

    &:nth-of-type(5) {
      max-width: 80px;
    }

    &:nth-of-type(6) {
      max-width: 95px;
    }

    &:nth-of-type(7) {
      max-width: 95px;
    }

    &:nth-of-type(8) {
      max-width: 80px;
    }

    &:nth-of-type(9) {
      max-width: 80px;
    }

    &:nth-of-type(10) {
      max-width: 70px;
    }

    &:nth-of-type(11) {
      max-width: 85px;
    }
    &:nth-of-type(12) {
      max-width: 75px;
      min-width: fit-content;
    }
  }
}

.apiCollectionForm {
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: fit-content;
  margin-bottom: $spacing_medium;
  border: 1px solid $color-border_lightGrey;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: $spacing_large;
  border-radius: $border-radius_small;
  background-color: $color-white;
}

.input {
  flex: 1 0 18%;

  &Buttons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 68px;
    flex: 1 0 18%;

    button {
      flex: 1 1 100%;
      height: 40px;
    }

    *[type='button'] {
      flex: auto;
      padding-right: 0;
    }
  }
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: $gap_xxlarge;
}

.checkboxCell {
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    border: 1px solid #212121;
    border-radius: 3px;
  }
}

input[type='reset'] {
  padding: $spacing_small $spacing_large;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius_xsmall;
  font-style: normal;
  font-weight: $font-weight_medium;
  font-size: 16px;
  line-height: $spacing_large;
  background-color: $color-white;
  color: $color-red_action;
  border: none;
  cursor: pointer;

  &:hover {
    color: $color-red_hover;
  }
}

.searchLayout {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
.searchDetailsContainer {
  padding: $spacing_small $spacing_large;
  background-color: lightgray;
  position: relative;
  color: $color-text_black;
  border-radius: $border-radius_small;
  .triangle {
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: $spacing_small solid lightgray;
  }
  .searchDetails {
    font-weight: $font-weight_medium;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: $color-text_black;
    margin: $margin-0;
  }
}

.searchErrorContainer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    margin-bottom: $spacing_xsmall;
  }
  .iconContainer {
    background-color: $color-orange_warningLight;
    height: 60px;
    width: 60px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $spacing_large;

    svg {
      color: $color_orange_warningDark;
      height: 25px;
      width: 25px;
    }
  }
}

.arrowAsc,
.arrowDesc {
  padding-left: $spacing-xxsmall;
  padding-top: 1.5px;
  cursor: pointer;
  text-align: left;
  display: block;
  display: inline;
}

.arrowDesc {
  svg {
    height: $spacing_medium;
    width: $spacing_xsmall;
  }
}

.alertBanner {
  display: block;
  margin-top: $spacing_medium;
}

td.cellContainer:nth-of-type(12) {
  text-align: center;
}
