@import '../../../styles/index.scss';

.productDetailsModalContainer {
  min-height: 100vh;
  min-width: 1231px;
  padding: $spacing_xlarge;

  .contentContainer {
    padding-bottom: $spacing_large;
  }
  .tabsContainer {
    border-top: 1px solid $color-background_highlight;
    padding-top: $spacing_large;
    margin-left: 285px;

    .tabsWrapper {
      border-left: 1px solid $color-background_highlight;
      padding-left: $spacing_medium;
    }
  }
}

.priceBreakdownUpper {
  padding-top: $spacing_large;
  display: flex;
  gap: $spacing_large;
  .priceBreakDownItem {
    border-right: 1px solid $color-background_highlight;
    padding-right: $spacing_large;
    margin-bottom: $spacing_large;

    .headlineValueUppercase {
      text-transform: uppercase;
    }
  }

  .priceBreakDownItem:last-of-type {
    border-right: none;
  }
}

.tableContainer {
  width: 100%;
  table-layout: auto;
  display: table;
  border-collapse: collapse;

  .tableHeader {
    width: 100%;
    background-color: $color-background_emphasis;
    table-layout: auto;
    display: table-header-group;
    .headerRow {
      display: flex;
      justify-content: flex-end;
      gap: $gap_xlarge;
      background: $color-background_emphasis;
      padding: $padding_medium;
      width: 100%;
      border-top: 1px solid $color-background_highlight;
      display: table-row;

      th {
        color: $color-table_label;
        text-transform: uppercase;
        font-family: $text-primary-font-family;
        font-weight: $font-weight_normal;
        font-size: 10px;
        line-height: 13px;
        padding: $padding_medium;
      }
      th:first-of-type {
        position: absolute;
        left: -10000px;
        top: auto;
        width: calc(100% - 240px);
        height: 1px;
        overflow: hidden;
      }
    }
  }

  .bodyRow {
    padding: $padding_medium;
    display: table-row;
    td {
      color: $color-text_paragraph;
      border-top: 1px solid $color-background_highlight;
      padding: $padding_medium;

      &:nth-of-type(2),
      &:nth-of-type(3) {
        text-align: end;
      }
    }
  }

  td,
  th {
    width: 110px;
  }
}

.priceHistoryUpper {
  margin-top: 24px;

  .productHistoryTable {
    .tableContainer .headerRow {
      th,
      th:first-of-type {
        color: $color-table_label;
        text-transform: uppercase;
        font-family: $text-primary-font-family;
        font-weight: $font-weight_normal;
        font-size: 10px;
        line-height: 13px;
        padding: $padding_medium;
        position: initial;
        text-align: left;
      }
    }

    .tableContainer .bodyRow {
      padding: $padding_medium;
      display: table-row;
      td {
        text-align: right;
      }
      td:first-of-type {
        text-align: left;
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .toggleButton {
        visibility: visible;
        margin-top: 16px;

        &Hidden {
          visibility: hidden;
        }

        &IconTransform {
          svg {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
