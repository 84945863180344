@import '../styles/index.scss';

.contentContainer {
  background-color: $color-border_lightGrey;
}

.heading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacing_large;
  height: 40px;
  z-index: 1;
  .buttonsRight {
    display: flex;
    margin-left: auto;
  }
}

.subHeadings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing_large 0;

  .buttonContainer {
    display: flex;
    background: $color-background_highlight;
    align-items: center;
    padding: $spacing_xsmall $spacing_medium;
    gap: $gap_xlarge;
    border-radius: $border-radius_small;
    min-width: fit-content;

    .headingToolTip {
      display: flex;
      gap: $gap_medium;
      align-items: center;

      h5 {
        font-size: 16px;
      }
    }
  }
}

.cardHeadingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: $spacing_large;
  gap: $gap_medium;
}

.priceRequestWrapper {
  display: flex;
  flex-direction: column;
  gap: $gap_xxlarge;
}
