@import '../../styles/index.scss';

.ResourceGrid {
  display: grid;
  gap: $spacing_medium $spacing_large;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.ResourceGridContainer {
  margin-bottom: $spacing_large;
}

.gridContainer_2cols {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
}

.gridContainer_2cols .ResourceGrid {
  gap: $spacing_small;
}

.gridContainer_2cols_column {
  display: flex;
  flex-direction: column;
  gap: $spacing_small;
}

.resourceSections {
  display: grid;
  gap: $spacing_large;
  margin-bottom: $spacing_large;
}

.section {
  margin-bottom: $spacing_small;

  &:last-child {
    margin-bottom: 0;
  }
}

.sectionHeading {
  margin-top: $spacing_large;
}

.sectionSubHeading {
  margin-bottom: $spacing_medium;
}

.resourceHeading {
  margin-bottom: $spacing_small;
  font-weight: 700;
}

.ResourceCard {
  padding: $spacing_small;
  border: 1px solid $color-background_highlight;
  border-radius: $border-radius_small;
  transition: all 0.2s ease-in-out;
  position: relative;
  min-height: 102px;
}

.ResourceCard:hover,
.ResourceCard:focus {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: $color-background_emphasis;
}

.coverLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ResourceCardHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing_small;
}

.ResourceCardTitle {
  margin-bottom: 2px;
  font-weight: 700;
}

.ResourceCardTag {
  padding: 4px 6px;
  background: $color-background_highlight;
  color: $color-text_highlight;
  font-size: 12px;
  border-radius: 4px;
}

.tagBlue {
  background: $color-background_information;
  color: $color-text_information;
}

.tagGreen {
  background: $color-background_green;
  color: $color-green_successDark;
}

.tagOrange {
  background: $color-background_yellow;
  color: $color-orange_warningDark;
}

.tagRed {
  background: $color-background_red;
  color: $color-text_red;
}

.ResourceCardDescription {
  margin-bottom: $spacing_medium;
  color: $color-text_paragraph;
  font-size: 12px;
}

.noResults {
  padding: $spacing_large;
  text-align: center;
  background-color: $color-background_lightGrey;
  color: $color-text_paragraph;
  width: 100%;
  border-radius: $border-radius_small;
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.disclaimers {
  margin: calc($spacing_medium * -1) 0 $spacing-xxsmall;
  padding-left: $spacing_large;
  padding-right: $spacing_large;
  color: $color-text_paragraph;

  p {
    line-height: 14px;
    letter-spacing: 0px;
  }
}

.disclaimers > * + * {
  margin-top: $spacing_medium;
}
