@import '../../styles/index.scss';

.tabContainer {
  font-style: normal;
  font-weight: $font-weight_normal;
  font-size: 16px;
  line-height: $spacing_large;
  color: $color-white;
  margin-right: $spacing_large;
  padding-bottom: 5px;

  .navLink {
    padding-bottom: 0.4rem;
    border-bottom: 1px solid $color-primary_base;
    color: $color-white;
    text-decoration: none;
  }

  .navLink:active,
  .navLink:hover {
    border-bottom: 1px solid $color-white;
  }
}
