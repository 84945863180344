@import '../../styles/index.scss';

.prDetailsForm {
  &__row {
    display: flex;
    gap: $gap_xxlarge;
    margin-bottom: 1.5rem;

    &Bg {
      background: $color-background_emphasis;
      padding: 1.5rem 1.5rem 0.625rem;
      margin-bottom: 1.5rem;

      &Error {
        padding-bottom: 1.5rem;
      }
    }

    &LastRow {
      margin-bottom: 0;
    }
  }

  &__fields {
    flex: 1 1 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: $gap_xxlarge;

    & > div {
      flex: 1 1 33%;
      max-width: 48%;
    }

    &:only-child > div {
      max-width: 100%;
      width: 100%;
    }
  }

  &__fieldsDate {
    flex: 1 1 33%;
    max-width: 60%;
  }

  &__squareButton {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    padding: 8px 14px;
    margin: $margin-0 $margin-0 21px;
  }

  &__reason {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $gap_xxlarge;
    background: $color-background_emphasis;
    padding: 1.5rem;

    label {
      max-width: none;
    }

    .justification {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &_row {
      display: flex;
      gap: $gap_xxlarge;
    }

    & > * {
      flex: 1 1 48%;
    }
  }

  &__alignBottom {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__actions {
    display: flex;
    gap: $gap_xlarge;
    justify-content: flex-start;
    width: 100%;
    padding: 1.5rem 0;
    border-top: 1px solid $color-border_lightGrey;
    border-bottom: 1px solid $color-border_lightGrey;

    #closeButton {
      //Intentionally Empty
    }

    *:last-child:not(#closeButton) {
      margin-left: auto;
    }

    .buttonBack {
      padding-left: 0;
    }
  }
}
