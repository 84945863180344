@import '../../styles/index.scss';

.submittedByContainer {
  display: flex;
  gap: $spacing_large;
  width: fit-content;
  margin-top: $spacing_large;

  .section {
    flex: 1 1 auto;
    color: $color-text_paragraph;
    padding: $spacing-xxsmall $spacing_large 0 0;

    &:not(:last-child) {
      border-right: 1px solid $color-background_highlight;
    }

    span {
      font-size: 13px;
      line-height: 1.5;
    }

    &__heading {
      font-size: 10px;
      line-height: 1.75;
      color: $color-table_label;
      text-transform: uppercase;
    }
  }
}
