@import '../../styles/index.scss';

.label {
  font-weight: $font-weight_medium;
  font-size: 14px;
  line-height: 22px;
  color: $color-text_black;

  &Error {
    color: $color-text_red;
    display: flex;
    align-items: center;
  }

  &Disabled {
    color: $color-text_placeholder;
  }

  .errorIcon {
    height: 17px;
    display: flex;
    align-items: center;
    width: fit-content;
    padding-top: 3px;

    svg {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .tooltipContainer {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);

    &Top {
      bottom: 100%;
      transform: translate(-50%, -50%);
    }
    &Bottom {
      top: 100%;
      transform: translate(-50%, 25%);
    }
  }
}

.label:hover .tooltipContainer {
  visibility: visible;
}
