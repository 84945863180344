@import '../../styles/index.scss';

.textLink {
  font-style: normal;
  font-weight: $font-weight_medium;
  font-size: 16px;
  line-height: $spacing_large;
  text-decoration: none;
  display: flex;
  align-items: center;

  &Primary {
    color: $color-white;
  }

  &Secondary {
    color: $color-red_action;

    &:hover {
      color: $color-red_hover;
      background-color: none;
      border: none;
    }
  }

  .iconLeft {
    margin-right: 6px;
    display: flex;
  }

  .iconRight {
    margin-left: 6px;
    display: flex;
  }

  &Small {
    font-size: 12px;
    line-height: 14px;
    font-weight: $font-weight_medium;
  }

  &Button {
    background-color: $color-primary_base;
    padding: $spacing_small 0;
    color: $color-white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: $gap_large;
    border-radius: $border-radius_xsmall;
    font-style: normal;
    font-weight: $font-weight_medium;
    font-size: 16px;
    line-height: $spacing_large;
    min-width: 248px;
    max-height: $spacing_xlarge;
    border: 0;

    &:hover {
      cursor: pointer;
      background-color: $color-primary_hover;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &Disabled {
      background-color: $color-background_mediumGrey;
      border: none;

      &:hover {
        background-color: $color-background_mediumGrey;
        box-shadow: none;
        cursor: default;
      }
    }

    &Secondary {
      border: 1px solid $color-primary_base;
      background-color: $color-white;
      color: $color-primary_base;
      padding: $spacing_xsmall $spacing_large;

      &:hover {
        background-color: $color-white;
        color: $color-primary_hover;
        border: 1px solid $color-primary_hover;
        box-shadow: 0px 4px 4px #00000040;
      }
    }

    &SecondaryDisabled {
      border: 1px solid $color-background_mediumGrey;
      color: $color-background_mediumGrey;
      background: $color-white;
      &:hover {
        background-color: $color-white;
        box-shadow: none;
        border: 1px solid $color-background_mediumGrey;
        color: $color-background_mediumGrey;
      }
    }
  }
}
