@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

$text-default-font-stack: 'Inter', 'Helvetica Neue', Helvetica, Arial,
  sans-serif;

$text-heading-font-family: $text-default-font-stack;
$text-primary-font-family: $text-default-font-stack;

//Weights
$font-weight_normal: 400;
$font-weight_medium: 500;
$font-weight_bold: 700;
