@import '../../styles/index.scss';

.formFileUpload {
  width: 672px;
  text-align: center;
  position: relative;
  margin-top: $spacing_large;
}

.inputFileUpload {
  display: none;
}

.labelFileUpload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 3px;
  background-color: $color-white;
  transition: all 0.125s ease;
  padding: $spacing_xlarge;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E9EDF5' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  color: $color-primary_base;

  &:hover {
    background-color: $color-background_emphasis;
    color: $color-headings_black;
    cursor: pointer;
  }
}

.uploadButton {
  font-size: 18px;
  border: none;
  background-color: transparent;
  color: $color-headings_black;
  font-weight: $font-weight_medium;
  margin: 0 0 $spacing_medium;
  line-height: 1.75em;
  pointer-events: none;

  div {
    color: $color-text_paragraph;
  }
}

.dragActive {
  background-color: $color-background_emphasis;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23687182' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  color: $color-headings_black;
}

.dragFileElement {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.userUploadDisplay {
  display: flex;
  align-items: center;
  border-radius: 2px;
  border: 1px solid $color-background_mediumGrey;
  margin: $spacing_large 0;
  font-weight: $font-weight_bold;
  padding: $spacing_xsmall;

  & > svg {
    margin-right: $spacing_xsmall;
  }

  button {
    padding: 0.5rem;
    margin-left: auto;
  }
}

.userUploadActions {
  display: flex;
  gap: $spacing_medium;
  margin-top: $spacing_large;
}

.errorContainer {
  margin: $spacing_large 0;
}
