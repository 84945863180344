@import '../../styles/index.scss';

.productCardContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .productWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: $gap_medium;
    word-wrap: break-word;
    padding-right: $spacing_large;

    &External {
      width: 354px;
    }

    &Internal {
      width: 238px;
    }

    .productStatus {
      display: flex;
      gap: $gap_xxlarge;
      color: $color-text_paragraph;
    }

    .productName {
      color: $color-red_action;
      margin-top: $spacing-xxsmall / 2;
    }

    .productCase {
      display: flex;
      gap: 24px;
    }

    button.productDetails {
      margin-top: $spacing_xsmall;
    }
  }

  .productTable {
    display: flex;
    flex-direction: column;
    gap: $gap_xlarge;
    padding-left: $spacing_large;
    border-left: 1px solid $color-background_highlight;
    align-items: flex-end;

    .tableContainer {
      width: 100%;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .toggleButton {
      visibility: visible;

      &Hidden {
        visibility: hidden;
      }

      &IconTransform {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    .modalContainer {
      display: none;
    }
  }

  .tableHeaderContainer {
    background-color: $color-background_emphasis;
    color: $color-table_label;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 13px;

    .headerCells {
      text-align: left;
      vertical-align: middle;
      padding: $spacing-xxsmall $spacing_xsmall;
      white-space: normal;
      overflow-wrap: break-word;
      position: relative;
      text-transform: uppercase;
      font-weight: $font-weight_normal;
    }

    .headerCells span {
      visibility: hidden;
      position: absolute;
      left: 0px;
      right: 0;
      top: 80%;
      z-index: 1;
      cursor: pointer;
    }

    .headerCells:hover span {
      visibility: visible;
    }
  }

  .table {
    font-weight: $font-weight_normal;
    font-size: 13px;
    line-height: 17px;
    border-collapse: collapse;
  }

  .tableRow {
    border-top: 1px solid $color-background_highlight;

    &:first-of-type {
      border-top: none;
    }

    &:last-of-type {
      border-bottom: 1px solid $color-background_highlight;
    }

    &Hidden {
      display: none;
    }

    .prCell {
      padding: 8.5px;
      max-width: 100%;
      min-width: fit-content;
      cursor: pointer;

      &:first-of-type {
        padding-right: 50px;
        max-width: 80px;
        min-width: fit-content;
        padding: 0;
      }
    }
  }

  tr.tableRow {
    &:hover {
      background-color: $color-background_lightGrey;
    }
  }
}

// prepend end date with arrow icon
.endDateContainer {
  position: relative;
  svg {
    color: $color-background_mediumGrey;
    position: absolute;
    height: 9px;
    width: auto;
    left: -11px;
    top: $spacing_xsmall;
  }
}
