// Useful variables to be used across mutliple components

$z-index_header: 100;
$z-index_footer: 100;
$z-index_modal: 200;
$z-index_overlay: 150;
$z-index_loadingOverlay: 250;

$spacing-xxsmall: 4px;
$spacing_xsmall: 8px;
$spacing_small: 12px;
$spacing_medium: 16px;
$spacing_large: 24px;
$spacing_xlarge: 48px;

$gap_xsmall: 4px;
$gap_small: 6px;
$gap_medium: $spacing_xsmall;
$gap_large: 10px;
$gap_xlarge: $spacing_medium;
$gap_xxlarge: $spacing_large;

$border-radius_xsmall: 2px;
$border-radius_small: 4px;
$border-radius_medium: 6px;
$border-radius_large: 8px;

$width-full-container: 1230px;
$width-mid-container: 768px;
$width-modal-sidebar: 285px;
$width-modal-content: 850px;
$width-min-delete-modal: 768px;
$height-min-delete-modal: 325px;

$margin-0: 0;
$page-margin_top: 150px;

$padding-0: 0;
$padding_medium: 10px;

$avatar_size: 40px;

$input-line-height: 22px;
$input-border: 1px;
$input-padding: 9px;
