@import '../../styles/index.scss';

.PriceRequestComments {
  padding: $spacing_large;
  background: $color-background_emphasis;
  border-radius: $border-radius_small;

  &__heading {
    color: $color-headings_black;
    margin: $margin-0 $margin-0 $spacing_large;
  }

  &__form {
    margin-top: ($spacing_xsmall * -1);
    padding-bottom: $spacing_large;
    border-bottom: 1px solid $color-border_lightGrey;

    input::placeholder {
      color: $color-text_placeholder;
      opacity: 0.5;
    }

    &Content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 42px;
      overflow: hidden;

      * {
        margin: 0 !important;
      }
    }
  }

  &__item {
    padding: $spacing_medium 0;
    border-top: 1px solid $color-border_lightGrey;
    color: $color-text_paragraph;

    &_heading {
      display: flex;
      gap: $spacing_xsmall;
    }

    &:last-child {
      padding-bottom: 0;
    }

    span {
      display: block;
      margin-bottom: $spacing_xsmall;
    }

    p {
      line-height: 22px;
      font-size: 14px;
      overflow-wrap: anywhere;
    }
  }
}
