@import '../../styles/index.scss';

.heading {
  color: $color-headings_black;
  font-weight: $font-weight_medium;
  margin: $margin-0;

  &Title {
    font-size: 20px;
    line-height: 24px;
    color: $color-white;
  }

  &2 {
    font-size: 28px;
    line-height: 34px;
  }

  &3 {
    font-size: 20px;
    line-height: $spacing_large;
  }

  &4 {
    font-size: 18px;
    line-height: 22px;
  }

  &5 {
    font-size: 14px;
    line-height: 18px;
  }

  .headingIcon {
    color: $color-red_action;
  }

  &Action {
    color: $color-red_action;
    overflow-wrap: anywhere;
  }

  .productBlockTitle {
    font-size: 18px;
  }
}
