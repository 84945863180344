@import "../../styles/index.scss";

.checkboxContainer {
  height: 100%;
  width: 100%;
}


/* The container */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
  width: 100%;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  bordeR: 1px solid $color-text_grey;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;

  svg {
    height: $spacing_xsmall;
    color: $color-white;
  }

  svg {
    height: $spacing_xsmall;
    color: $color-white;
  }

  &Selected {
    background-color: $color-text_green;
    border-color: $color-text_green;
  }

  &Disabled {
    background-color: $color-background_mediumGrey;
    border-color: $color-background_mediumGrey;
    pointer-events: none;

    &:hover {
      pointer-events: none;
    }
  }
}