@import '../../styles/index.scss';

$padding-td-horizontal: 5px;
$width-svg-enddate: 10px;

.tableContainer {
  width: 100%;
  table-layout: auto;
  display: table;
  border-collapse: collapse;

  .tableHeader {
    width: 100%;
    background-color: $color-background_emphasis;
    table-layout: auto;
    display: table-header-group;
    .headerRow {
      display: flex;
      justify-content: flex-end;
      gap: $gap_xlarge;
      background: $color-background_emphasis;
      padding: $padding_medium;
      width: 100%;
      border-top: 1px solid $color-background_highlight;
      display: table-row;

      th {
        color: $color-table_label;
        text-transform: uppercase;
        font-family: $text-primary-font-family;
        font-weight: $font-weight_normal;
        font-size: 10px;
        line-height: 13px;
        padding: $padding_medium;
      }
      th:first-of-type {
        position: absolute;
        left: -10000px;
        top: auto;
        width: calc(100% - 240px);
        height: 1px;
        overflow: hidden;
      }
    }
  }

  .bodyRow {
    padding: $padding_medium;
    display: table-row;
    td {
      color: $color-text_paragraph;
      border-top: 1px solid $color-background_highlight;
      padding: $padding_medium;

      &:nth-of-type(2),
      &:nth-of-type(3) {
        text-align: end;
      }
    }
  }

  th {
    > span {
      max-width: 64px;
    }

    span div {
      min-width: min-content;
      p {
        min-width: 160px;
      }
    }
  }

  th:nth-of-type(3),
  th:nth-of-type(4) {
    span {
      max-width: 40px;
    }
  }

  td,
  th {
    width: 110px;

    &:nth-of-type(1),
    &:nth-of-type(1) {
      width: 52px;
    }
  }
}

.priceRequestPriceHistoryUpper {
  margin-top: 24px;
  .tableContainer {
    width: 100%;
    background-color: $color-white;
    margin-top: 16px;

    .table {
      width: 100%;
      font-weight: 400;
      font-size: 13px;
      line-height: 17px;
      border-collapse: collapse;

      .th {
        background: $color-background_emphasis;
        text-transform: uppercase;
        color: $color-table_label;
        padding: 13.5px 6px;
        text-align: left;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        border-bottom: 1px solid $color-border_lightGrey;
        vertical-align: middle;
        letter-spacing: -0.35px;

        &:hover {
          cursor: pointer;
          background: $color-background_hoverTableActive;
        }

        &:nth-of-type(1) {
          &::before {
            content: '';
            display: none;
            left: 0;
            height: 0;
            border: 0;
          }
        }
      }

      tr {
        height: 45px;
      }

      td {
        border-bottom: 1px solid $color-border_lightGrey;
        max-height: 45px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 $padding-td-horizontal;
        vertical-align: middle;
        text-align: right;

        * {
          letter-spacing: -0.25px;
        }
      }

      td:nth-child(-n + 3) {
        text-align: left;
      }
    }

    tr.tableRow:hover {
      background-color: $color-background_emphasis !important;
    }

    tr.tableRowSelected {
      background-color: $color-background_green;
    }

    .th {
      background: $color-background_emphasis;

      .headerColumnWrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .shortLabel {
          max-width: 50px;
        }

        .mediumLabel {
          max-width: 60px;
        }
      }
    }

    .th .headerColumnWrapper .tooltipContainer {
      visibility: hidden;
      position: absolute;
      z-index: 1;
      cursor: pointer;
      left: 50%;
      transform: translateX(-50%);

      &Top {
        bottom: 100%;
        transform: translate(-50%, -50%);
      }
      &Bottom {
        top: 100%;
        transform: translate(-50%, 25%);
      }
    }

    .th:hover .tooltipContainer {
      visibility: visible;
    }

    .divContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      label {
        span {
          visibility: visible;
          position: relative;
          left: 0;
        }
      }
    }

    .th {
      height: auto;
      padding-left: 6px;
    }

    .arrowAsc,
    .arrowDesc {
      padding-left: $spacing-xxsmall;
      cursor: pointer;
      text-align: left;
      display: block;
      display: inline;
    }

    .arrowDesc {
      svg {
        height: 16px;
        width: 8px;
      }
    }

    td.cellContainer {
      overflow: visible;
    }

    .buttonContainer {
      margin-top: $spacing_medium;

      .toggleButtonIconTransform {
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }
}

// prepend end date with arrow icon
.endDateContainer {
  position: relative;
  svg {
    color: $color-background_mediumGrey;
    position: absolute;
    height: 9px;
    width: $width-svg-enddate;
    left: calc(-1 * ($padding-td-horizontal + ($width-svg-enddate / 2)));
    top: $spacing_xsmall;
  }
}
