@import '../../styles/index.scss';

.homeHero {
  display: flex;
  background: #333; //fallback for when image doesn't load
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  height: 400px;
  margin-top: -31px;
  position: relative;
  overflow: hidden;
}

.heroBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heroContainer {
  display: flex;
  margin: 0 auto;
  position: relative;
  width: $width-full-container;
  z-index: 0;
  justify-content: space-between;
  align-items: center;
}

.heroHeading {
  color: $color-white;
  font-size: 32px;
}

.heroButton {
  justify-content: space-between;
  font-size: 16px;
  padding: 14px 16px;
  width: 380px;
  align-items: center;
}

.heroButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing_large;
}
